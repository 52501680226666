












































































.title{
  margin: 0.5rem;
}
  .tag{
      //top right of the card
      position: absolute;
      top: 0;
      right: 0;
  }

  .invoiceCard{
      margin-bottom: 1rem;
  }
  .rightbox{
      text-align: right;
      b{
          margin-right: 1rem;
      }
  }
