

























































































@import "~vars";

.invoice-combination {
  cursor: pointer;
}

.invoice-body {
  border-bottom: $table-cell-border;
}

.table.is-striped tbody.invoice-body:nth-child(odd) {
  background-color: $table-striped-row-even-background-color;
}

.invoice-body .combination-row {
  background-color: rgba($grey-darker, 0.15) !important;
}
